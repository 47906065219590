<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-card-header>
                    <h4>Kriteria Influencer</h4>
                </b-card-header>
                <b-card-body>
                    <a href="javascript:;" @click="back()">Back</a>
                    <hr>
                    <validation-observer
                        ref="step2"
                    >
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="step2"
                            >
                            <b-row>
                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Kategori Influencer"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Kategori Influencer"
                                            vid="content-type"
                                            rules="required"
                                            >
                                            <treeselect
                                                id="content-type"
                                                name="content-type"
                                                v-model="content_type"
                                                :state="errors.length > 0 ? false:null"
                                                :multiple="true"
                                                :options="optionsTreeContent" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                
                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Lokasi Influencer"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Lokasi Influencer"
                                            vid="location"
                                            rules="required"
                                            >
                                            <treeselect
                                                id="location"
                                                name="location"
                                                v-model="location"
                                                :state="errors.length > 0 ? false:null"
                                                :multiple="true"
                                                :options="optionsTreeLocation" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                
                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Jenis Kelamin Influencer"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Jenis Kelamin Influencer"
                                            vid="gender"
                                            rules="required"
                                            >
                                            <treeselect
                                                id="gender"
                                                name="gender"
                                                v-model="gender"
                                                :state="errors.length > 0 ? false:null"
                                                :multiple="true"
                                                :options="optionsTreeGender" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Kisaran Usia Influencer"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Kisaran Usia Influencer"
                                            vid="age"
                                            rules="required"
                                            >
                                            <treeselect
                                                id="age"
                                                name="age"
                                                v-model="age"
                                                :state="errors.length > 0 ? false:null"
                                                :multiple="true"
                                                :options="optionsTreeAge" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-button
                                        type="submit"
                                        block
                                        variant="primary"
                                        >
                                        Selanjutnya
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BButton,
    BForm
} from 'bootstrap-vue'
import axios from '@axios'
export default {
    name: 'CampaignStep2Project',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormSelect,
        BButton,
        BForm,

        Treeselect,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            options: [
                { value: null, text: 'Please select an option' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Simple Option' },
                { value: { C: '3PO' }, text: 'This is an option with object value' },
                { value: 'd', text: 'This one is disabled', disabled: true },
            ],
            selected: null,

            optionsTreeContent: [],
            content_type: null,

            optionsTreeLocation: [],
            location: null,

            optionsTreeGender: [
                {
                    'id': 'gender=All',
                    'label': 'Semua Jenis Kelamin',
                    children: [
                        {
                            id: 'gender=Laki-Laki',
                            label: 'Laki-Laki',
                        }, {
                            id: 'gender=Perempuan',
                            label: 'Perempuan',
                        }
                    ]
                }
            ],
            gender: null,

            optionsTreeAge: [
                {
                    'id': 'age=All',
                    'label': 'Semua Umur',
                    children: [
                        {
                            id: 'age=12-17',
                            label: '12 - 17 tahun',
                        }, {
                            id: 'age=18-24',
                            label: '18 - 24 tahun',
                        }, {
                            id: 'age=35-49',
                            label: '35 - 49 tahun',
                        }, {
                            id: 'age=>50',
                            label: '> 50 tahun',
                        },
                    ]
                }
            ],
            age: null,

            // validation rules
            required,
            email
        }
    },
    mounted() {
        let _ = this
        _.getContentType()
        _.getLocation()

        if ( _.$route.name == 'campaign-edit-2-project' ) {
            console.log('HERE')
            _.getCampaignData()
        }
    },
    methods: {
        getContentType(){
            let _ = this
            axios.get('/misc/types/content-types')
                .then(resp => {
                    let contentType = resp.data.data.map((v,i) => {
                        return {
                            'id': 'contentType=' + v.name,
                            'label': v.name
                        }
                    })

                    _.optionsTreeContent = [{
                        'id': 'contentType=All',
                        'label': 'Semua Kategori Influencer',
                        children: contentType
                    }]
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getLocation() {
            let _ = this
            axios.get('/misc/types/provinces')
                .then(resp => {
                    console.log(resp.data)
                    let location = resp.data.data.map((v,i) => {
                        return {
                            'id': 'location=' + v.name,
                            'label': v.name
                        }
                    })

                    _.optionsTreeLocation = [{
                        'id': 'location=All',
                        'label': 'Semua Lokasi Influencer',
                        children: location
                    }]
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        step2(){
            let _ = this
            this.$refs.step2.validate().then(success => {
                if (success) {
                    console.log('success')
                    let _ = this

                    let contentTypes = _.content_type.map((v,i) => {
                        return v.split('contentType=')[1]
                    }).filter(v => v != 'All')
                    contentTypes = contentTypes.length == 0 ? _.optionsTreeContent[0].children.map((v,i) => {
                        return v.id.split('contentType=')[1]
                    }) : contentTypes

                    let location = _.location.map((v,i) => {
                        return v.split('location=')[1]
                    }).filter(v => v != 'All')
                    location = location.length == 0 ? _.optionsTreeLocation[0].children.map((v,i) => {
                        return v.id.split('location=')[1]
                    }) : location

                    let genders = _.gender.map((v,i) => {
                        return v.split('gender=')[1]
                    }).filter(v => v != 'All')
                    genders = genders.length == 0 ? _.optionsTreeGender[0].children.map((v,i) => {
                        return v.id.split('gender=')[1]
                    }) : genders

                    let age = _.age.map((v,i) => {
                        return v.split('age=')[1]
                    }).filter(v => v != 'All')
                    age = age.length == 0 ? _.optionsTreeAge[0].children.map((v,i) => {
                        return v.id.split('age=')[1]
                    }) : age

                    let model = {
                        campaignID:localStorage.getItem('campaignID'),
                        contentTypes: contentTypes,
                        locations:location,
                        genders:genders,
                        ageRanges:age,
                        followers:"1000-5000"
                    }

                    axios.post('/advertiser/campaigns/influencer-criteria', model)
                        .then(resp => {
                            if ( _.$route.name == 'campaign-edit-2-project' ) {
                                _.$router.push({name: 'campaign-edit-3'})
                            } else {
                                _.$router.push('step-3')
                            }
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                } else {
                    console.log('failed validation')
                }
            })
        },
        getCampaignData() {
            let _ = this
            axios.get('/advertiser/campaigns/' + _.$route.params.id)
                .then(resp => {
                    let _ = this
                    let campaign = resp.data.data
                    localStorage.setItem('campaignID', campaign.id)
                    if ( campaign.influencerCriteria ) {
                        _.content_type = campaign.influencerCriteria.contentTypes.map(ct => {
                            return 'contentType=' + ct
                        })
                        _.location = campaign.influencerCriteria.locations.map(lc => {
                            return 'location=' + lc
                        })
                        _.gender = campaign.influencerCriteria.genders.map(lc => {
                            return 'gender=' + lc
                        })
                        _.age = campaign.influencerCriteria.ageRanges.map(ag => {
                            return 'age=' + ag
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        back(){
            let _ = this
            let campaignID = localStorage.getItem('campaignID') || _.$route.params.id
            _.$router.push({name: 'campaign-edit-1', params: {id: campaignID }})
        }
    }
}
</script>