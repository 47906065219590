var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-card-header',[_c('h4',[_vm._v("Kriteria Influencer")])]),_c('b-card-body',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.back()}}},[_vm._v("Back")]),_c('hr'),_c('validation-observer',{ref:"step2"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.step2($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Kategori Influencer","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Kategori Influencer","vid":"content-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{attrs:{"id":"content-type","name":"content-type","state":errors.length > 0 ? false:null,"multiple":true,"options":_vm.optionsTreeContent},model:{value:(_vm.content_type),callback:function ($$v) {_vm.content_type=$$v},expression:"content_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Lokasi Influencer","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Lokasi Influencer","vid":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{attrs:{"id":"location","name":"location","state":errors.length > 0 ? false:null,"multiple":true,"options":_vm.optionsTreeLocation},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis Kelamin Influencer","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Jenis Kelamin Influencer","vid":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{attrs:{"id":"gender","name":"gender","state":errors.length > 0 ? false:null,"multiple":true,"options":_vm.optionsTreeGender},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Kisaran Usia Influencer","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Kisaran Usia Influencer","vid":"age","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{attrs:{"id":"age","name":"age","state":errors.length > 0 ? false:null,"multiple":true,"options":_vm.optionsTreeAge},model:{value:(_vm.age),callback:function ($$v) {_vm.age=$$v},expression:"age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","block":"","variant":"primary"}},[_vm._v(" Selanjutnya ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }